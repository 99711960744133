import dynamic from 'next/dynamic';
// import router, { useRouter } from 'next/router';
import { useRouter } from 'next/router';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import BrandLogo from '../BrandLogo/BrandLogo';
import { LogoContainer, UserContainer, UserName } from '../Header/HeaderStyle';
import { MaskIcon } from '../Icons/Icons';
import {
  HeaderButtons,
  HeaderButtonsContainer,
  IconContainer,
  MobileNavbarContainer,
  RightIconsContainer,
} from './MobileHeaderStyle';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { UserDetailsInterface } from '../../Interface/UserDetailsInterface';
import {
  openLoginModalRedux,
  openMobileSearchDialog,
  openRlcSelectorRedux,
} from '../../redux/modalSlice';

// import CountryListNav from '../CountryListNav/CountryListNav';
import Cookies from 'universal-cookie';
import { COLORS } from '../../constants/colors';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { AppThemeContext } from '../../contexts/ThemeContext';
import { saveEventV3 } from '../../utils/eventTracking';
import { canShowBackButton } from '../../utils/mobileNavigatioinUtils';
import { WE_USER_EVENT_THEME_TOGGLE } from '../../utils/we';
import CCFlag from '../CCFlag/CCFlag';
import HeaderCartIcon from '../Header/components/HeaderCartIcon';

const SidebarMegamenu = dynamic(() => import('../SidebarMegamenu'), {
  ssr: false,
});
const MUIDrawer = dynamic(() => import('../MUIDrawer/MUIDrawer'), {
  ssr: false,
});
// import ProfilePurple from '../../public/icons/profile-purple.svg';

// const MUIDrawer = dynamic(() => import('../MUIDrawer/MUIDrawer'), {
//   ssr: false,
// });

interface UserIcon {
  user: UserDetailsInterface;
}

const UserIcon = (props: UserIcon) => {
  return (
    <UserContainer>
      <IconContainer>
        <MaskIcon
          url="/icons/profile-24.svg"
          selected={true}
          margin="0 0 0 0"
          height="24px"
          width="24px"
        />
      </IconContainer>
      <UserName>{props.user.first_name}</UserName>
    </UserContainer>
  );
};

interface Props {
  type?: 'user' | string;
}

const MobileHeader = (props: Props) => {
  const pageType = props.type ?? '';
  const [drawer, setDrawerStatus] = useState(false);
  const appTheme = useContext(AppThemeContext);
  const theme = useTheme();

  const { cleverTap } = useAnalytics();

  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [backPath, setBackPath] = useState<string>('/');
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);
  const router = useRouter();
  const { cartItems } = useAppSelector((state) => state.cart);

  const [showSearchIcon, setShowSearchIcon] = useState<boolean>(false);

  const [openGeneralSidebar, setOpenGeneralSidebar] = useState<boolean>(false);

  const { locationDetails } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (openGeneralSidebar) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [openGeneralSidebar]);

  const handleThemeToggle = useCallback(() => {
    const cookies = new Cookies();
    if (appTheme?.theme === 'dark') {
      appTheme.changeTheme('light');
      cookies.set('theme', 'light', { maxAge: 1000000 });
    } else {
      appTheme?.changeTheme('dark');
      cookies.set('theme', 'dark', { maxAge: 1000000 });
    }
  }, [appTheme]);

  useEffect(() => {
    if (router) {
      const backOptions = canShowBackButton(router);
      // console.log(backOptions);
      if (backOptions.showBack) {
        setShowBackButton(true);
        setBackPath(backOptions.path);
      }
    }
  }, [router]);

  useEffect(() => {
    let quantity = 0;
    for (let i = 0; i < cartItems.length; i++) {
      quantity = quantity + cartItems[i].qty;
    }
    setNumberOfCartItems(quantity);
  }, [cartItems]);

  const { isLoggedIn, user } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const toggleDrawer = () => {
    setDrawerStatus(!drawer);
  };

  const listenToScroll = useCallback(() => {
    let heightToHideFrom = 80;
    const winScroll =
      typeof window.scrollY === 'undefined'
        ? window.pageYOffset
        : window.scrollY;
    if (winScroll > heightToHideFrom) {
      !showSearchIcon && setShowSearchIcon(true);
    } else {
      setShowSearchIcon(false);
    }
  }, [showSearchIcon]);

  useEffect(() => {
    if (router.asPath === '/') {
      window.addEventListener('scroll', listenToScroll);
      return () => window.removeEventListener('scroll', listenToScroll);
    } else {
      setShowSearchIcon(true);
    }
  }, [listenToScroll]);

  return (
    <>
      <MobileNavbarContainer>
        {/* {(router.pathname.substring(5, 14) === '/selling/' ||
          router.pathname.includes('selling-accounts')) && ( */}
        <IconContainer
          onClick={
            router.pathname.substring(5, 14) === '/selling/' ||
            router.pathname.includes('selling-accounts')
              ? toggleDrawer
              : () => setOpenGeneralSidebar(true)
          }
        >
          <MaskIcon
            url="/icons/hamburger-black.svg"
            selected={true}
            margin="0 0 0 0"
            height="24px"
            width="24px"
            color="#fff"
          />
        </IconContainer>
        {/* )} */}
        {/* <IconContainer onClick={toggleDrawer} style={{ cursor: 'pointer' }}>
          <MaskIcon
            url="/icons/hamburger-black.svg"
            selected={true}
            margin="0 0 0 0"
            height="24px"
            width="24px"
            color="#fff"
          />
        </IconContainer> */}
        {/* {showBackButton && (
          <IconContainer onClick={() => router.back()}>
            <MaskIcon
              url="/icons/arrow-back-24.svg"
              selected={true}
              margin="0 0 0 0"
              height="24px"
              color={COLORS.white}
              width="24px"
              style={{ transform: 'rotate(180deg)' }}
            />
          </IconContainer>
        )} */}

        <Link href="/" passHref>
          <LogoContainer>
            <BrandLogo />
          </LogoContainer>
        </Link>

        {/* <div style={{ display: drawer ? 'block' : 'none', cursor: 'pointer' }}> */}
        {/* {drawer && ( */}
        <MUIDrawer
          drawerState={drawer}
          toggleDrawer={toggleDrawer}
          setDrawerStatus={setDrawerStatus}
          onClickOutside={() => setDrawerStatus(false)}
        />
        {/* )} */}
        {/* </div> */}
        <RightIconsContainer>
          {/* <SearchIconContainer id="search-bar">
            <ProductSearchInput type="mobile" />
          </SearchIconContainer> */}
          <HeaderButtonsContainer>
            {showSearchIcon && (
              <HeaderButtons
                onClick={() => {
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'search',
                    properties: '',
                    value: [],
                    from: router,
                  });
                  dispatch(openMobileSearchDialog());
                }}
                aria-label="search"
              >
                <MaskIcon
                  url="/icons/search-24.svg"
                  width="24px"
                  height="24px"
                  selected={true}
                  margin="0"
                  color={COLORS.white}
                />
              </HeaderButtons>
            )}
            {pageType === 'about' ? null : (
              <HeaderButtons
                onClick={() => {
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'rlc-button',
                    properties: '',
                    value: [],
                    from: router,
                  });
                  dispatch(openRlcSelectorRedux());
                }}
              >
                <CCFlag
                  countryCode={
                    locationDetails?.flag_country_code ||
                    locationDetails?.country_code
                  }
                  margin="0 0 0 0"
                  showGlobe={true}
                  header={true}
                />
              </HeaderButtons>
            )}
            {pageType === 'about' ? null : (
              <HeaderButtons
                onClick={() => {
                  WE_USER_EVENT_THEME_TOGGLE(
                    {
                      previousTheme: theme.mode,
                      pageUrl: window.location.href,
                      currentTheme: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                    cleverTap
                  );
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'theme',
                    properties: '',
                    value: [theme.mode === 'dark' ? 'light' : 'dark'],
                    from: router,
                    jsonData: {
                      old: theme.mode,
                      new: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                  });
                  handleThemeToggle();
                }}
                aria-label="theme-toggle"
              >
                <MaskIcon
                  margin="0"
                  width="24px"
                  height="24px"
                  url={`/icons/${
                    appTheme?.theme === 'dark' ? 'moon' : 'star'
                  }.svg`}
                  selected={true}
                  color={COLORS.white}
                />
              </HeaderButtons>
            )}
            {router.pathname === '/[slug]' ? (
              <Link passHref href="/cart">
                <a aria-label="cart">
                  <HeaderButtons
                    onClick={() => {
                      saveEventV3({
                        category: 'header',
                        action: 'click',
                        label: 'cart',
                        properties: '',
                        value: ['/cart'],
                        from: router,
                      });
                    }}
                    aria-label="cart"
                  >
                    <HeaderCartIcon location="mobile" />
                  </HeaderButtons>
                </a>
              </Link>
            ) : (
              isLoggedIn &&
              user && (
                <Link passHref href="/favourites">
                  <a>
                    <HeaderButtons
                      onClick={() => {
                        saveEventV3({
                          category: 'header',
                          action: 'click',
                          label: 'favourites',
                          properties: '',
                          value: ['/favourites'],
                          from: router,
                        });
                      }}
                    >
                      <MaskIcon
                        url="/icons/favorite-24.svg"
                        width="24px"
                        height="24px"
                        selected={true}
                        color={COLORS.white}
                        margin="0"
                      />
                    </HeaderButtons>
                  </a>
                </Link>
              )
            )}
            {isLoggedIn && user ? (
              <Link passHref href="/user">
                <a>
                  <HeaderButtons
                    onClick={() => {
                      saveEventV3({
                        category: 'header',
                        action: 'click',
                        label: 'user profile',
                        properties: '',
                        value: [],
                        from: router,
                      });
                    }}
                    aria-label="profile"
                  >
                    <MaskIcon
                      height="24px"
                      width="24px"
                      margin="0 0"
                      url="/icons/profile-24.svg"
                      color={COLORS.white}
                      selected={true}
                    />
                  </HeaderButtons>
                </a>
              </Link>
            ) : (
              <HeaderButtons
                onClick={() => {
                  dispatch(openLoginModalRedux());
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'user profile',
                    properties: '',
                    value: [],
                    from: router,
                  });
                }}
                aria-label="profile"
              >
                <MaskIcon
                  height="24px"
                  width="24px"
                  margin="0 0"
                  url="/icons/profile-24.svg"
                  color={COLORS.white}
                  selected={true}
                />
              </HeaderButtons>
            )}
            {/* <HeaderButtons>
              <Link href="/cart" passHref>
                <a aria-label="Cart icon">
                  <HeaderCartIcon />
                </a>
              </Link>
            </HeaderButtons> */}
            {/* TO DO - Remove this when new profile is complete */}
            {/* {isLoggedIn && user ? (
              <BasicPopover boxPadding={0} button={<UserIcon user={user} />}>
                <UserPopoverList />
              </BasicPopover>
            ) : (
              <IconContainer
                onClick={() => {
                  dispatch(openLoginModalRedux());
                }}
              >
                <MaskIcon
                  url="/icons/profile-24.svg"
                  selected={true}
                  margin="0 0 0 0"
                  height="24px"
                  width="24px"
                />
              </IconContainer>
            )} */}
          </HeaderButtonsContainer>
        </RightIconsContainer>
      </MobileNavbarContainer>
      {/* {openGeneralSidebar && ( */}
      <SidebarMegamenu
        open={openGeneralSidebar}
        handleClose={() => setOpenGeneralSidebar(false)}
      />
      {/* )} */}
    </>
  );
};

export default memo(MobileHeader);
