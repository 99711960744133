export const canShowBackButton = (x: any) => {
  let res = {
    showBack: true,
    path: '/',
  };

  const pathname = x.pathname;

  if (
    pathname === '/' ||
    // pathname.includes('/store') ||
    // pathname.includes('/search') ||
    pathname.includes('/categories')
  ) {
    res = {
      showBack: false,
      path: '/',
    };
  } else if (pathname === '/user') {
    res = {
      showBack: true,
      path: '/',
    };
  } else if (pathname.includes('/support')) {
    res = {
      showBack: false,
      path: '/',
    };
  } else if (pathname === '/[slug]') {
    res = {
      showBack: true,
      path: '/',
    };
  } else if (pathname === '/user/my-shop') {
    res = {
      showBack: false,
      path: '/',
    };
  } else if (pathname.substring(0, 6) === '/user/') {
    if (pathname.substring(5, 14) === '/selling/') {
      res = {
        showBack: false,
        path: '/',
      };
    } else {
      res = {
        showBack: true,
        path: '/user',
      };
    }
  } else {
    res = {
      showBack: true,
      path: '/',
    };
  }

  return res;
};
