import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { COLORS } from '../../../constants/colors';
import { useAppSelector } from '../../../redux/hooks';
import { saveEventV3 } from '../../../utils/eventTracking';
import { MaskIcon } from '../../Icons/Icons';
import { Count, IconContainer, NumberCircleContainer } from '../HeaderStyle';

const HeaderCartIcon = ({
  color,
  location,
}: {
  color?: string;
  location?: string;
}) => {
  const router = useRouter();
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);

  const { cartItems } = useAppSelector((state) => state.cart);

  useEffect(() => {
    let quantity = 0;
    for (let i = 0; i < cartItems.length; i++) {
      quantity = quantity + cartItems[i].qty;
    }
    setNumberOfCartItems(quantity);
  }, [cartItems]);

  return (
    <IconContainer
      onClick={() => {
        saveEventV3({
          category: 'header',
          action: 'click',
          label: 'cart',
          properties: '',
          value: ['/cart'],
          from: router,
        });
      }}
    >
      {numberOfCartItems > 0 ? (
        <NumberCircleContainer
          style={{ right: location === 'mobile' ? '-6px' : '6px' }}
        >
          <Count>
            {numberOfCartItems > 9 ? <span>9+</span> : numberOfCartItems}
          </Count>
        </NumberCircleContainer>
      ) : null}
      <MaskIcon
        url="/icons/shopping-cart-24.svg"
        height="24px"
        width="24px"
        selected={true}
        color={color ?? COLORS.white}
        margin="0 0 0 0 "
      />
    </IconContainer>
  );
};

export default HeaderCartIcon;
