import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const HeaderWrapper = styled.div`
  transition: 0.3s;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 101;
  // z-index: 1999;
  max-height: 108px;
  // margin-bottom: 36px;
  @media (max-width: ${WIDTH.laptopMin}) {
    display: none;
    padding: 0;
  }
`;

export const HeaderContainer = styled.div<{ headerColor: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.headerColor === 'translucent'
      ? 'rgba(33, 33, 33, 0.7)'
      : COLORS.headerBg};
  mix-blend-mode: normal;
  backdrop-filter: blur(40px);
  border-bottom: 2px solid rgba(255, 255, 255, 0.07);
  box-shadow: ${(props) => props.theme.shadows.s3};
`;

export const LoginButtonSpan = styled.span`
  margin: 0 10px;
  cursor: pointer;
  font-family: Onest-Medium;
  color: ${COLORS.blackPrimary};
`;

export const LoginButtonSpanMobile = styled.span`
  margin: 0 0px;
  cursor: pointer;
  color: ${COLORS.blackPrimary};
`;

export const SiteHeader = styled.div<{ infiniteHeader: boolean }>`
  max-width: ${(props) => (props.infiniteHeader ? '100%' : '1728px')};
  margin: auto;
  top: 0;
  height: 72px;
  z-index: 100;
  width: 100%;
  padding: 12px 40px;
  @media (min-width: ${WIDTH.desktopMin}) {
    // max-width: 90%;
    width: 100%;
    margin: auto;
  }
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
`;

export const LoadingText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: bold;
  font-size: 22px;
  font-family: Onest-SemiBold;
  color: ${COLORS.primaryLight1};
`;

export const UserPart = styled.div`
  align-items: center;
  display: flex;
  // grid-template-columns: auto auto auto auto auto;
  margin-left: auto;
  gap: 4px;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  place-content: center;
  width: 48px;
  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  height: 48px;
  transition: 0.3s all ease;
  &:hover {
    background: rgb(255, 255, 255, 0.15);
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: auto;
    &:hover {
      background: transparent;
    }
  }
`;

export const UserIconContainer = styled.div`
  cursor: pointer;
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${COLORS.greyLight3};
  }
`;

export const NumberCircleContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 6px;
  z-index: 2;
  @media (max-width: ${WIDTH.tabletMax}) {
    top: 8px;
    right: -16px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  // min-width: 122px;
`;

export const UserContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const UserName = styled.div`
  margin-left: 10px;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
  color: ${COLORS.blackDark2};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;

export const UserListContainer = styled.div`
  padding: 10px 0;
`;

export const UserListItem = styled.div`
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.primaryLight1};
  }
`;

export const UserListIconContainer = styled.div`
  margin-right: 7px;
`;

export const UserListText = styled.div``;

export const LocationContainer = styled.div`
  margin-left: 35px;
`;

export const CurrencyButtonContainer = styled.span`
  cursor: pointer;
  display: flex;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 14px;
  font-family: Onest-Bold;
  color: ${COLORS.black};
  &:hover {
    background-color: ${COLORS.greyLight3};
  }
`;

export const CartIconContainer = styled.div`
  border-radius: 50px;
  // &:hover {
  //   background: ${COLORS.greyLight3};
  // }
`;

export const NavButton = styled.button`
  font-size: 16px;
  font-family: Onest-Bold;
  line-height: 16px;
  color: ${COLORS.black};
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  height: 40px;
  margin-right: 8px;
  border-radius: 24px;
  gap: 12px;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${COLORS.greyLight3};
  }
`;

export const LoggedOutButtonsContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 12px;
  margin-left: 8px;
`;

export const LoginBtnWrapper = styled.div`
  button {
    background: rgba(255, 255, 255, 0.1);
    color: ${COLORS.white};
    font-family: Onest-Bold;
    :hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
`;

export const HeartIconContainer = styled.button`
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${COLORS.greyLight3};
  }
`;

export const LocalizationHeader = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${(props) => props.theme.palette.borderRadius.b2};
  padding: 5px 10px;
  height: 48px;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-family: Onest-Bold;
  color: ${COLORS.white};
  transition: 0.3s all ease;
  &:hover {
    background: rgb(255, 255, 255, 0.15);
  }
`;

export const Count = styled.div`
  width: 18px;
  height: 18px;
  color: ${(props) => props.theme.palette.white.main};
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 12px !important;
  span {
    font-size: 0.625rem !important;
  }
`;
