import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const MobileNavbarContainer = styled.nav`
  padding: 10px 16px;
  display: none;
  height: 64px;

  @media (max-width: ${WIDTH.laptopMin}) {
    display: flex;
  }
  width: 100%;
  position: sticky;
  backdrop-filter: blur(4px);
  background-color: ${COLORS.headerBg};
  top: 0px;
  z-index: 25;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 40px;
  width: 40px;
  z-index: 12;
  transition: min-width 150ms ease-in-out;
  overflow: hidden;
  position: relative;
  &:focus-within {
    min-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    #cross-icon {
      display: block;
    }
    #search-icon-mobile {
      display: none;
    }
  }
`;

export const RightIconsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const LoginButton = styled.span`
  display: block;
  line-height: 19px;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${COLORS.blackPrimary};
  font-size: 16px;
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: ${WIDTH.laptopMin}) {
    margin-left: 16px;
  }
`;

export const HeaderButtons = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
`;
