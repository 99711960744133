import { memo } from 'react';
import ReactCountryFlag from 'react-country-flag';
import styled, { useTheme } from 'styled-components';
import { MaskIcon } from '../Icons/Icons';

interface Props {
  countryCode: string | undefined;
  margin?: string;
  showGlobe?: boolean;
  header?: boolean;
}

function CCFlag({
  countryCode,
  margin,
  showGlobe = false,
  header = false,
}: Props) {
  const theme = useTheme();
  return !countryCode || countryCode === 'OTH' ? (
    <MaskIcon
      url="/icons/globe-black.svg"
      height="24px"
      width="24px"
      selected
      margin={margin ?? '0 8px 0 0'}
      color={header ? '#fff' : theme.palette.text.t1}
    />
  ) : (
    <FlagWrapper style={{ margin: margin ?? '0 8px 0 0' }}>
      {showGlobe && (
        <MaskIcon
          url="/icons/globe-black.svg"
          height="16px"
          width="16px"
          selected
          margin="0 0"
          color={'#fff'}
          style={{
            position: 'absolute',
            zIndex: 1,
            top: '-7px',
            right: '-7px',
          }}
        />
      )}
      <FlagContainer>
        <ReactCountryFlag
          countryCode={countryCode}
          svg
          style={{
            fontSize: '24px',
            lineHeight: '24px',
          }}
          aria-label={countryCode}
        />
      </FlagContainer>
    </FlagWrapper>
  );
}

export default memo(CCFlag);

const FlagWrapper = styled.div`
  display: flex;
  height: 16px;
  width: 24px;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const FlagContainer = styled.div`
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  height: 16px;
  width: 24px;
`;
